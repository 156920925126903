<template>
  <div class="grid-reservation" style="width: 100%">
    <!-- GRID LEGEND -->
    <div
      class="text-sm text-body text-left p-4"
      style="
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: 10ch;
      "
    >
      <div></div>
      <div style="align-self: center">LE MATIN</div>
      <div style="align-self: center">L'APRÈS MIDI</div>
    </div>
    <!-- RESERVATION GRID -->
    <div class="grid-reservation" style="overflow-x: auto">
      <div
        v-for="(day, idx) in dataset"
        :key="idx"
        class="day-block"
        style="min-width: 10ch"
      >
        <div class="text-sm text-body text-center">
          {{ getReadableDay(day.date) }}
        </div>
        <div class="text-sm text-body text-center">
          {{ getDayMonth(day.date) }}
        </div>
        <div class="dflex flexcol">
          <GridParkinReservationBtn
            :btnContent="day.am"
            :btnState="getBtnState(day, 'am')"
            :info="day"
            style="margin-bottom: 6px"
          ></GridParkinReservationBtn>
          <GridParkinReservationBtn
            :btnContent="day.pm"
            :btnState="getBtnState(day, 'pm')"
            :info="day"
          ></GridParkinReservationBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GridParkinReservationBtn from "./GridParkinReservationBtn.vue";

export default {
  name: "GridParkingReservation",
  components: {
    GridParkinReservationBtn,
  },
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getBtnState(day, period) {
      if (day[period] === undefined) {
        return "unavailable";
      }
      if (day[period] === 0) {
        return "full";
      }
      return "freePlace";
    },

    /**
     * timeout
     * HELPER FUNCTION TO SIMULATE REQUEST DELAY
     * @param {integer} ms : time to wait in millis
     */
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    /**
     * getFormatedDate
     * @param {String} date format 'yyyy-mm-dd' dd/mm/yyyy
     * @returns {Date} iso date
     */
    getFormatedDate(date) {
      const splitted = date.split("-");
      return new Date(`${splitted[1]}-${splitted[0]}-${splitted[2]}`);
    },

    /**
     * getReadableDay
     * @param {Date} date
     * @returns {String} day of the week abbr like "LUN" for lundi
     */
    getReadableDay(date) {
      const weekDays = ["DIM", "LUN", "MAR", "MER", "JEU", "VEN", "SAM"];
      const day = new Date(date).getDay();
      return weekDays[day];
    },

    /**
     * getDayMonth
     * @param {String} date format 'yyyy-mm-dd'
     * @returns {String} 'dd/mm'
     */
    getDayMonth(date) {
      const splitted = date.split("-");
      return `${splitted[2]}/${splitted[1]}`;
    },
  },
};
</script>

<style scoped>
.grid-reservation {
  display: flex;
  gap: 6px;
}

.day-block {
  padding: 0px;
}
</style>
