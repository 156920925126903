<template>
  <div class="mt-12 w-full">
    <h4 class="mb-2">Liste</h4>
    <div v-if="reservations.length === 0">
      <p>Pas de réservation sur la période</p>
    </div>
    <v-card>
      <v-row class="d-flex align-center">
        <v-col>
          <v-text-field
            v-model="search"
            rounded-sm
            hide-details
            outlined
            background-color="rgba(255,255,255,.9)"
            color="rgba(0,0,0,.6)"
            light
            placeholder="Nom, email, periode, place, plaque d'immatriculation, date"
            class="
              my-6
              input-style
              font-size-input font-weight-normal
              placeholder-lighter
              input-alternative input-icon
              border
            "
          >
            <template slot="prepend-inner">
              <v-icon color="rgba(0,0,0,.6)" size=".875rem"
                >fas fa-search</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-menu
            transition="slide-y-transition"
            offset-y
            offset-x
            min-width="300"
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                color="#fff"
                class="font-weight-bolder btn-outline-default py-4 px-2"
                small
                v-bind="attrs"
                v-on="on"
              >
                Autres actions
              </v-btn>
            </template>

            <v-list class="py-0d">
              <v-list-item
                class="pa-4 list-item-hover-active"
                @click="cancelMultipleReservation"
              >
                <v-list-item-title class="p-6"
                  >Annuler {{ selectedItems.length }} réservation{{
                    selectedItems.length > 1 ? "s" : ""
                  }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            :elevation="0"
            color="#cb0c9f"
            class="
              font-weight-bolder
              btn-danger
              bg-gradient-info
              py-4
              px-4
              mt-auto
              ml-2
            "
            small
            @click="convertToXLS"
          >
            Exporter la table
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="filteredData"
        :single-select="false"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        fixed-header
        maxheight="70vh"
        item-key="resaUserId"
        show-select
        class="elevation-0"
        :items-per-page="filteredData.length"
        hide-default-footer
      >
        <template v-slot:[`item.period`]="{ item }">
          {{ item.period.toUpperCase() }}
        </template>
        <template v-slot:[`item.userSurname`]="{ item }">
          <div v-if="item.user">
            <p class="text-sm font-weight-bold mt-4 mb-0">
              {{ item.userSurname }}
              {{ item.userName }}
            </p>
            <p class="text-sm text-secondary">
              {{ item.userEmail || "n/c" }}
            </p>
          </div>
          <div v-else>n/c</div>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <p
            class="mt-4 p-0 text-blue"
            style="text-decoration: underline; cursor: pointer"
            @click.stop="cancelReservation(item.resaid, item.userId)"
          >
            Annuler la réservation
          </p>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default {
  name: "ReservationDetailsTable",
  props: {
    reservations: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      search: "",
      selectedItems: [],
      sortBy: "spot",
      sortDesc: false,
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "DATE",
          value: "reservationDMY",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "PERIODE",
          value: "period",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "PLACE ATTRIBUÉE",
          value: "spotnumber",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "VÉHICULE",
          value: "vehicule",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "UTILISATEUR",
          value: "userSurname",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "",
          value: "action",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },

  computed: {
    filteredData() {
      const dataToScan = [
        "parkingid",
        "period",
        "spotnumber",
        "userName",
        "userSurname",
        "userEmail",
        "immatList",
      ];
      if (this.search === "") return this.reservations;
      return this.reservations.filter((elm) => {
        return dataToScan.some((k) => {
          // -- Check array
          if (Array.isArray(elm[k])) {
            const immatMatch = elm[k].find((immat) =>
              immat.toLowerCase().includes(this.search.toLowerCase())
            );
            return immatMatch;
          }
          // -- Check string value
          const search = this.search.toLowerCase();
          return elm[k] && elm[k].toLowerCase().includes(search);
        });
      });
    },
  },

  methods: {
    cancelReservation(resaid, userid) {
      axios
        .delete(
          `${this.$store.getters.apiurl}/reservationsparking/${resaid}?userid=${userid}`
        )
        .then((res) => {
          if (res.status === 200) {
            this.$emit("refreshData");
          }
        })
        .catch((err) => {
          console.error("cancelReservation ERROR", err);
        });
    },

    cancelMultipleReservation() {
      const reservationsToCancel = this.selectedItems.map((reservation) => {
        return axios.delete(
          `${this.$store.getters.apiurl}/reservationsparking/${reservation.resaid}?userid=${reservation.userid}`
        );
      });
      this.fetchingData = true;
      axios
        .all(reservationsToCancel)
        .then(
          axios.spread((...responses) => {
            console.log("Cancel multiple reservations", responses);
            this.$emit("refreshData");
          })
        )
        .catch((errors) => {
          console.error("Cancel multiple reservations ERROR: ", errors);
        });
    },

    /**
     *
     * @param {string} date - formated YYYY-MM-DD
     * @returns {string} date - formated DD/MM/YYYY
     */
    getDateDMY(date) {
      const dateArr = date.split("-");
      return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
    },

    convertToXLS() {
      let spreadsheet = [];
      this.filteredData.forEach((data) => {
        let row = [
          data.reservationDMY,
          data.period,
          data.spotnumber,
          data.vehicule,
          data.userName,
          data.userSurname,
          data.userEmail,
        ];
        spreadsheet.push(row);
      });
      this.createExcelFile(spreadsheet, "Réservations");
    },

    createExcelFile(dataToXls, title = "tableau", subject = "export") {
      let wb = XLSX.utils.book_new();
      wb.Props = {
        Title: title,
        Subject: subject,
        Author: "Dynapark",
        CreatedDate: new Date(),
      };
      wb.SheetNames.push(title);
      let ws_data = dataToXls;
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets[title] = ws;
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        `${title}-${subject}.xlsx`
      );
    },
  },
};
</script>

<style scoped>
.custom-table {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
