<template>
  <div class="mb-4">
    <span
      type="button"
      class="p-4 font-weight-medium text--secondary"
      @click="backTo"
      >&#60; Retour</span
    >
  </div>
</template>

<script>
export default {
  name: "BtnRetour",

  methods: {
    backTo() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
