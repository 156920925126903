<template>
  <div class="m-8">
    <div v-if="pendingRequest" class="grid-center-h-v">
      <v-progress-circular
        :size="32"
        :width="2"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <!-- DISPLAY FORM OR MESSAGE -->
    <div v-else>
      <v-row
        v-if="message"
        class="mt-2"
        style="flex-direction: column; gap: 1rem"
      >
        <p
          style="font-weight: bold; text-align: center"
          :style="{ color: messageType }"
        >
          {{ this.message }}
        </p>
        <v-btn
          elevation="0"
          color="#fff"
          class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
          small
          @click="cancelForm"
        >
          FERMER
        </v-btn>
      </v-row>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Pour</label
            >
            <v-autocomplete
              v-model="selectedUser"
              :items="users"
              item-text="fullname"
              item-value="id"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mt-2
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Véhicule</label
            >
            <v-select
              v-model="selectedVehicle"
              :items="getVehicules"
              item-text="content"
              item-value="immat"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- SELECT PARKING -->
        <v-row>
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Parking</label
            >
            <v-select
              v-model="selectedParking"
              :items="parkings"
              item-text="name"
              return-object
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-select>
          </v-col>
        </v-row>

        <!-- SELECT GATE -->
        <v-row>
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Porte</label
            >
            <v-select
              v-model="selectedGate"
              :items="getGates"
              item-text="name"
              return-object
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center mb-4">
          <v-btn
            elevation="0"
            color="#fff"
            class="font-weight-bolder py-4 px-2 mr-4"
            :class="{ 'btn-outline-danger': canForceOpen }"
            :disabled="!canForceOpen"
            title="Selectionner un parking puis une ouverture"
            @click="forceOpen"
          >
            OUVERTURE FORCÉE
          </v-btn>
          <v-btn
            :elevation="0"
            color="#cb0c9f"
            class="
              font-weight-bolder
              btn-danger
              bg-gradient-info
              py-4
              px-2
              mt-auto
            "
            :disabled="!canOpen"
            @click="openGate"
          >
            OUVRIR
          </v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TriggOpeningForm",

  data() {
    return {
      pendingRequest: false,
      message: null,
      messageType: null,
      users: null,
      selectedUser: null,
      vehicles: null,
      selectedVehicle: null,
      parkings: null,
      selectedParking: null,
      gates: null,
      selectedGate: null,
    };
  },

  beforeMount() {
    this.pendingRequest = true;
    const getUsers = axios.get(`${this.$store.getters.apiurl}/users`);
    const getParkings = axios.get(`${this.$store.getters.apiurl}/parkings`);
    axios
      .all([getUsers, getParkings])
      .then(
        axios.spread((...responses) => {
          const resUsers = responses[0];
          const resParkings = responses[1];
          this.users = resUsers.data.map((user) => {
            return {
              fullname: `${user.name || "..."} ${user.surname || "..."}`,
              ...user,
            };
          });
          this.parkings = resParkings.data;
          this.selectedParking = this.parkings.length ? this.parkings[0] : null;
          this.selectedGate = this.parkings[0].barriers[0];
          this.pendingRequest = false;
        })
      )
      .catch((err) => {
        this.pendingRequest = false;
        console.error("Get users & parkings ERROR: ", err);
      });
  },

  computed: {
    getVehicules() {
      const user = this.users
        ? this.users.find((user) => user.id === this.selectedUser)
        : null;
      return user && user.plates ? this.buildUserVehicleList(user.plates) : [];
    },

    getGates() {
      const currentParking = this.parkings
        ? this.parkings.find(
            (parking) => parking.id === this.selectedParking.id
          )
        : null;
      return currentParking?.barriers ?? [];
    },

    canOpen() {
      return (
        this.selectedUser &&
        this.selectedVehicle &&
        this.selectedParking &&
        this.selectedGate
      );
    },

    canForceOpen() {
      return this.selectedParking && this.selectedGate;
    },
  },

  watch: {
    selectedUser(nVal, oVal) {
      if (nVal !== oVal) {
        this.selectedVehicle = this.getVehicules.length
          ? this.getVehicules[0].immat
          : null;
      }
    },

    selectedParking(nVal) {
      this.selectedGate = nVal.barriers[0];
    },
  },

  methods: {
    cancelForm() {
      this.selectedUser = null;
      this.selectedVehicle = null;
      this.message = null;
      this.$emit("cancelForm");
    },

    buildUserVehicleList(plates) {
      if (!plates || plates.length === 0) {
        return [];
      }
      const vehicleName = {
        cars: "Voiture",
        motorbike: "Moto",
        bike: "Vélo",
        default: "Véhicule",
      };

      let vehicleList = [];
      const vehicleTypes = Object.keys(plates);
      vehicleTypes.forEach((k) => {
        const typeColl = plates[k].map((immat) => {
          const displayImmat = immat === "NOPLATE_CAR" ? "" : immat;
          return {
            type: k,
            immat: immat,
            content: `${
              vehicleName[k] ?? vehicleName.default
            } immat: ${displayImmat}`,
          };
        });
        vehicleList = [...vehicleList, ...typeColl];
      });
      return vehicleList;
    },

    /**
     * OPEN GATE
     * Open gate for a user with a specific vehicle
     */
    openGate() {
      this.message = null;
      this.pendingRequest = true;
      axios
        .get(
          `${this.$store.getters.apiurl}/open/${this.selectedParking.id}?userid=${this.selectedUser}&platenumber=${this.selectedVehicle}&barrierid=${this.selectedGate.barrierid}`
        )
        .then(() => {
          this.messageType = "green";
          this.message = "Ouverture réalisée!";
          this.pendingRequest = false;
        })
        .catch((err) => {
          this.messageType = "red";
          this.message = "L'ouverture a échouée";
          this.pendingRequest = false;
          console.error("Get users ERROR: ", err);
        });
    },

    forceOpen() {
      this.message = null;
      this.pendingRequest = true;
      axios
        .get(
          `${this.$store.getters.apiurl}/open/${this.selectedParking.id}?barrierid=${this.selectedGate.barrierid}&force=true`
        )
        .then((res) => {
          if (res.status === 200) {
            this.messageType = "green";
            this.message = "Ouverture réalisée!";
          } else {
            this.messageType = "red";
            this.message = "L'ouverture a échouée";
            this.pendingRequest = false;
          }
          this.pendingRequest = false;
        })
        .catch((err) => {
          this.messageType = "red";
          this.message = "L'ouverture a échouée";
          this.pendingRequest = false;
          console.error("Get users ERROR: ", err);
        });
    },
  },
};
</script>

<style scoped></style>
