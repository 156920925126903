<template>
  <div>
    <div v-if="pendingRequest" class="grid-center-h-v">
      <v-progress-circular
        :size="32"
        :width="2"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <!-- CONFIRMATION MESSAGE -->
      <div v-if="message">
        <p :style="{ color: messageStatus }">{{ this.message }}</p>
        <v-btn
          elevation="0"
          color="#fff"
          class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
          small
          @click="cancelForm"
        >
          FERMER
        </v-btn>
      </div>
      <!-- RESERVATION FORM -->
      <div v-else>
        <!-- CALENDAR SELECTION -->
        <v-row>
          <div class="period-selector">
            <div>
              <label for="start-date">Du</label>
              <input
                class="custom-date-picker"
                name="start-date"
                type="date"
                :min="today()"
                v-model="startDate"
              />
              <v-radio-group v-model="startAmPm">
                <v-radio label="AM" value="am"></v-radio>
                <v-radio label="PM" value="pm"></v-radio>
              </v-radio-group>
            </div>
            <div>
              <label for="end-date">Au</label>
              <input
                class="custom-date-picker"
                name="end-date"
                type="date"
                :min="startDate"
                v-model="endDate"
              />
              <v-radio-group v-model="endAmPm">
                <v-radio
                  label="AM"
                  value="am"
                  :disabled="!canReserveAm"
                ></v-radio>
                <v-radio label="PM" value="pm"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-row>
        <!-- USER SELECTION -->
        <v-row>
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Pour</label
            >
            <v-autocomplete
              v-model="selectedUser"
              :items="users"
              item-text="fullname"
              item-value="id"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mt-2
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="getSelectedUserVehicleList">
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Séléction du véhicule</label
            >
            <v-autocomplete
              v-model="selectedVehicle"
              :items="getSelectedUserVehicleList"
              item-text="platenumber"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mt-2
                mb-0
              "
              return-object
              outlined
              single-line
              height="36"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- PARKING SELECTION -->
        <v-row>
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Parking</label
            >
            <v-select
              v-model="selectedParking"
              :items="getUserParking"
              item-text="name"
              item-value="id"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- BUTTONS ACTION -->
        <v-row class="d-flex justify-space-between px-4 pb-4">
          <v-btn
            elevation="0"
            color="#fff"
            class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
            small
            @click="cancelForm"
          >
            ANNULER
          </v-btn>
          <v-btn
            :elevation="0"
            color="#cb0c9f"
            class="
              font-weight-bolder
              btn-danger
              bg-gradient-info
              py-4
              px-2
              mt-auto
            "
            small
            :disabled="!canReserve"
            @click="createReservation"
          >
            Réserver
          </v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { reservation400 } from "../../util/reservation400";

export default {
  name: "CreateReservationForm",

  data() {
    return {
      pendingRequest: false,
      date: null,
      startDate: null,
      startAmPm: "am",
      endDate: null,
      endAmPm: "am",
      users: null,
      selectedUser: null,
      userVehicleList: null,
      selectedVehicle: null,
      selectedParking: null,
      message: null,
      messageStatus: null,
    };
  },

  beforeMount() {
    this.pendingRequest = true;
    this.message = null;
    const getUsers = axios.get(`${this.$store.getters.apiurl}/users`);
    const getParkings = axios.get(`${this.$store.getters.apiurl}/parkings`);
    axios
      .all([getUsers, getParkings])
      .then(
        axios.spread((...responses) => {
          const resUsers = responses[0];
          const resParkings = responses[1];
          this.users = resUsers.data.map((user) => {
            return {
              fullname: `${user.name || "..."} ${user.surname || "..."}`,
              ...user,
            };
          });
          this.parkings = resParkings.data;
          this.pendingRequest = false;
        })
      )
      .catch((err) => {
        this.pendingRequest = false;
        console.error("Get users & parkings ERROR: ", err);
      });
  },

  computed: {
    getUserParking() {
      const user = this.users
        ? this.users.find((user) => user.id === this.selectedUser)
        : null;

      let parkingIdList = user?.mainparking ? [user.mainparking] : [];

      if (user?.secondaryparking) {
        parkingIdList = [...parkingIdList, ...user.secondaryparking];
      }

      return user && parkingIdList.length
        ? this.reorderParking(
            this.parkings.filter((parking) =>
              parkingIdList.includes(parking.id)
            ),
            parkingIdList
          )
        : [];
    },

    getSelectedUserVehicleList() {
      const selctedUser = this.users.find(
        (user) => user.id === this.selectedUser
      );
      if (!selctedUser) {
        return [];
      }
      return selctedUser?.vehicles || null;
    },

    canReserveAm() {
      return (
        new Date(this.endDate) > new Date(this.startDate) ||
        (this.startDate === this.endDate && this.startAmPm === "am")
      );
    },

    canReserve() {
      return (
        this.startDate &&
        this.endDate &&
        this.selectedUser &&
        this.selectedParking
      );
    },
  },

  watch: {
    startDate(nVal) {
      if (new Date(nVal) > new Date(this.endDate)) {
        this.endDate = nVal;
      }

      if (nVal === this.endDate && this.startAmPm === "pm") {
        this.endAmPm = "pm";
      }
    },

    startAmPm(nVal) {
      if (this.startDate === this.endDate && nVal === "pm") {
        this.endAmPm = "pm";
      }
    },

    selectedUser(nVal) {
      const user = this.users.find((user) => user.id === nVal);
      if (user.vehicles && user.vehicles[0]) {
        const firstVehicle = user.vehicles[0];
        this.selectedVehicle = firstVehicle;
      }
    },

    getUserParking(nVal) {
      this.selectedParking = nVal.length ? nVal[0].id : null;
    },
  },

  methods: {
    /**
     * TODAY
     * Return the current date used in min/max input date
     * @returns {string} today formated yyyy-mm-dd -> '2022-01-28'
     */
    today() {
      const today = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-");
      return today;
    },

    cancelForm() {
      this.startDate = null;
      this.startAmPm = "am";
      this.endDate = null;
      this.endAmPm = "am";
      this.selectedUser = null;
      this.vehicles = null;
      this.selectedParking = null;
      this.message = null;

      this.$emit("cancelForm");
    },

    buildUserVehicleList(plates) {
      if (!plates || plates.length === 0) {
        return [];
      }
      const vehicleName = {
        cars: "Voiture",
        motorbike: "Moto",
        bike: "Vélo",
        default: "Véhicule",
      };

      let vehicleList = [];
      const vehicleTypes = Object.keys(plates);
      vehicleTypes.forEach((k) => {
        const typeColl = plates[k].map((immat) => {
          return {
            type: k,
            immat: immat,
            content: `${vehicleName[k] ?? vehicleName.default} immat: ${immat}`,
          };
        });
        vehicleList = [...vehicleList, ...typeColl];
      });
      return vehicleList;
    },

    /**
     * REORDER PARKING BY PRIORITY
     * @param {PARKING[]} parkingList
     * @param {string[]} order - the user parkings id sorted [mainParking, ...secondaryparking]
     * @returns {PARKING[]} user parkings ordered by priority
     */
    reorderParking(parkingList, order) {
      let parkingReordered = [];
      order.forEach((id) => {
        const parking = parkingList.find((parking) => parking.id === id);
        if (parking) {
          parkingReordered.push(parking);
        }
      });
      return parkingReordered;
    },

    /**
     * CREATE RESERVATION
     */
    createReservation() {
      this.pendingRequest = true;
      this.message = null;
      const reservation = {
        userid: this.selectedUser,
        initdate: this.startDate,
        initperiod: this.startAmPm,
        enddate: this.endDate,
        endperiod: this.endAmPm,
        parkingid: this.selectedParking,
        vehicleid: this.selectedVehicle?.id || "",
      };
      // -- Clean reservation body before POST
      for (const [key, value] of Object.entries(reservation)) {
        if (value === undefined || value === "") {
          delete reservation[key];
        }
      }
      axios
        .post(`${this.$store.getters.apiurl}/reservationsparking`, reservation)
        .then((res) => {
          this.pendingRequest = false;
          this.$emit("newReservationDone");
          if (res.status === 201) {
            this.message = "Réservation effectuée";
            this.messageStatus = "green";
          } else {
            this.messageStatus = "orange";
            this.message = `Reservation effectuée (${res.data})`;
          }
        })
        .catch((err) => {
          this.pendingRequest = false;
          if (err.response.status == 400) {
            this.message = reservation400(err.response.data)["fr"];
          } else if (err.response.status == 404) {
            this.message = `Reservation non effectuée. Parking et/ou utilisateur non trouvé.`;
          } else {
            this.message = `Reservation non effectuée.`;
          }
          this.messageStatus = "red";
          console.error("Create reservation ERROR: ", err.response);
        });
    },
  },
};
</script>

<style scoped>
.custom-date-picker {
  height: 36px;
  margin-left: 1rem;
  border: 1px solid #dee2e6;
  padding: 0 16px;
  border-radius: 8px;
  color: #8994a8;
}

.period-selector {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  gap: 2rem;
  margin-left: 1rem;
}
</style>
