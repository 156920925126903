<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on }">
        <v-btn
          :elevation="0"
          color="#cb0c9f"
          class="
            font-weight-bolder
            btn-danger
            bg-gradient-info
            py-4
            px-2
            mt-auto
          "
          small
          v-on="on"
        >
          CRÉER UNE RESERVATION
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5"> Créer une reservation </v-card-title>

        <v-card-text>
          <CreateReservationForm
            :key="dialog"
            @cancelForm="closeDialog"
            @newReservationDone="newReservationDone"
          ></CreateReservationForm>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CreateReservationForm from "./CreateReservationForm.vue";

export default {
  name: "CreateReservation",
  components: {
    CreateReservationForm,
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    triggBtn() {
      console.log("CLIKED GRADIENT");
    },
    closeDialog() {
      this.dialog = false;
    },
    newReservationDone() {
      this.$emit("newReservationDone");
    },
  },
};
</script>

<style scoped></style>
