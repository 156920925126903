<template>
  <div class="container">
    <BtnRetour />
    <GestionCourante @newReservationDone="fetchPeriodData"></GestionCourante>,
    <!-- TABLEAU DES RESERVATIONS -->
    <div>
      <v-card class="card-shadow border-radius-xl px-4 py-4 w-100">
        <h3>Tableau des reservations</h3>
        <p class="text-sm text-body">Liste des reservations effectuées pour</p>

        <!-- FILTER QUERY-->
        <v-row class="px-4 py-4 align-start">
          <!-- select parking -->
          <v-select
            v-model="selectedParking"
            :items="parkings"
            item-text="name"
            item-value="id"
            color="rgba(0,0,0,.6)"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              border-radius-md
              select-style
              mt-2
              mb-0
            "
            style="max-width: 300px"
            outlined
            single-line
            height="36"
          >
          </v-select>
          <div class="period-selector">
            <!-- select start date -->
            <label for="start-date">Du</label>
            <input
              class="custom-date-picker"
              name="start-date"
              type="date"
              v-model="startDate"
            />
            <!-- select end date -->
            <label for="end-date">Au</label>
            <input
              class="custom-date-picker"
              name="end-date"
              type="date"
              :min="startDate"
              v-model="endDate"
            />
          </div>
          <!-- button to fetch data -->
          <v-btn
            elevation="0"
            color="#fff"
            class="font-weight-bolder btn-outline-default py-4 px-2 ml-4 mt-2"
            small
            @click="fetchPeriodData"
          >
            Afficher
          </v-btn>
        </v-row>

        <!-- DISPONIBILITY GRID -->
        <v-row class="px-4 py-4 mt-12">
          <h4>Disponibilités</h4>
          <div v-if="loadingDisponibilities" class="grid-center-h-v">
            <v-progress-circular
              :size="32"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <GridParkingReservation
            v-else
            class="mt-6"
            :dataset="disponibilities"
          ></GridParkingReservation>
        </v-row>

        <!-- RESERVATIONS TABLE -->
        <v-row class="px-4 py-4">
          <v-col>
            <div v-if="loadingReservations" class="grid-center-h-v">
              <v-progress-circular
                :size="32"
                :width="2"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <ReservationDetailsTable
              v-else
              :reservations="reservationList"
              @refreshData="fetchPeriodData"
            ></ReservationDetailsTable>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GestionCourante from "../../components/Dynapark/GestionCourante.vue";
import GridParkingReservation from "../../components/Dynapark/GridParkingReservation.vue";
import ReservationDetailsTable from "../../components/Dynapark/ReservationDetailsTable.vue";
import BtnRetour from "../../components/Dynapark/BtnRetour.vue";

/**
 * @reference typedef COMPLETE_RESERVATION from `../../JSdocModels/completeReservation`
 * @reference typedef DISPONIBILITIES from `../../JSdocModels/disponibilities`
 */

export default {
  name: "ReservationDetails",
  components: {
    GestionCourante,
    GridParkingReservation,
    ReservationDetailsTable,
    BtnRetour,
  },

  data() {
    return {
      loadingDisponibilities: false,
      loadingReservations: false,
      parkingId: null,
      startDate: null, // yyyy-mm-dd
      endDate: null, // yyyy-mm-dd
      selectedParking: null,
      parkings: [],
      reservationList: [],
      disponibilities: [],
    };
  },

  beforeMount() {
    this.parkingId = this.$route.params.id;
    this.fetchDisponibilities(this.parkingId);
    this.fetchReservations(this.parkingId);

    // -- Fetch parking list
    axios.get(`${this.$store.getters.apiurl}/parkings`).then((res) => {
      this.parkings = res.data;
      const parkingDefault = this.parkings.find((p) => p.id === this.parkingId);
      this.selectedParking = parkingDefault.id;
    });
  },

  created() {
    this.selectedParking = this.$route.params.id;

    // -- Initialize date for the current day
    const today = new Date()
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-");
    this.startDate = today;
    this.endDate = today;
  },

  watch: {
    startDate(nVal) {
      if (new Date(nVal) > new Date(this.endDate)) {
        this.endDate = nVal;
      }
    },
  },

  methods: {
    getVehicleTypeFromVehiculeId(vehiculeId) {
      if (!vehiculeId || !vehiculeId.includes("#")) return "_";
      const VEHICLE_NAME_CONVERTER = {
        car: "voiture",
        moto: "moto",
        bike: "vélo",
        scooter: "trottinette",
        soft: "mobilité douce",
      };

      const vehiculeType = vehiculeId.split("#")[1].toLowerCase();
      return VEHICLE_NAME_CONVERTER[vehiculeType] || "Véhicule non listé";
    },
    /**
     * BUILD RESERVATIONS DATASET WITH USER DATA
     * @param { USERS[] } users
     * @param { RESERVATIONS[] } reservations
     * @returns { COMPLETE_RESERVATION[] } reservations with full user data.
     */
    buildReservationTable(users, reservations) {
      if ([users, reservations].includes(undefined)) {
        return [];
      }

      const completeReservation = reservations.map((resa) => {
        let user = users.find((user) => user.id === resa.userid);
        if (user === undefined) {
          user = {
            name: "Utilisateur supprimé",
            id: "deletedUser",
          };
        }
        console.log("resa.vehicleid: ", resa.vehicleid);
        return {
          vehicule: this.getVehicleTypeFromVehiculeId(resa.vehicleid),
          userName: user?.name || "",
          userSurname: user?.surname || "",
          userEmail: user?.email || "",
          userId: user?.id || "",
          resaUserId: `${resa.resaid}-${user.id}`,
          reservationDMY: resa.reservationdate.split("-").reverse().join("/"),
          user,
          immatList: this.flatter(user.plates),
          ...resa,
        };
      });

      return completeReservation;
    },

    /**
     * SORT DISPONIBILITIES
     * Sort disponibilities in chronological order
     * @param {DISPONIBILITIES[]} disponibilities collection
     * @returns {DISPONIBILITIES[]} disponibilities collection sorted by date
     */
    sortDisponibilities(dispo) {
      return dispo.sort((a, b) => new Date(a.date) - new Date(b.date));
    },

    /**
     * FETCH RESERVATIONS
     * Fetch reservations and users to build
     * a complete dataset for reservationList
     *
     * @param {string} parkingId
     * @param {Date} start - 'yyyy-mm-dd' Optional default is undefined
     * @param {Date} end - 'yyyy-mm-dd' Optional default is undefined
     */
    fetchReservations(parkingId, start = undefined, end = undefined) {
      const users = axios.get(`${this.$store.getters.apiurl}/users`);
      const period = start && end ? `?init=${start}&end=${end}` : "";
      const reservations = axios.get(
        `${this.$store.getters.apiurl}/reservationsparking/${parkingId}${period}`
      );

      this.loadingReservations = true;
      axios
        .all([users, reservations])
        .then(
          axios.spread((...responses) => {
            const responseUsers = responses[0].data;
            const responseReservations = responses[1].data;
            this.reservationList = this.buildReservationTable(
              responseUsers,
              responseReservations
            );
            this.loadingReservations = false;
          })
        )
        .catch((errors) => {
          this.loadingReservations = false;
          console.error(errors);
        });
    },

    refreshReservations() {
      this.fetchReservations(this.parkingId, this.startDate, this.endDate);
      this.fetchDisponibilities(this.parkingId);
    },

    /**
     * FETCH DISPONIBILITIES
     * @param {string} parkingId
     * @param {Date} start - 'yyyy-mm-dd' Optional default is undefined
     * @param {Date} end - 'yyyy-mm-dd' Optional default is undefined
     */
    fetchDisponibilities(parkingId, start, end) {
      const period =
        start && end ? `?init=${start}&end=${end}&admin=true` : "?admin=true";
      this.loadingDisponibilities = true;
      axios
        .get(
          `${this.$store.getters.apiurl}/disponibilities/${parkingId}${period}`
        )
        .then((res) => {
          this.loadingDisponibilities = false;
          this.disponibilities = this.sortDisponibilities(res.data);
        })
        .catch((err) => {
          this.loadingDisponibilities = false;
          console.error("fetchDisponibilities ERROR: ", err);
        });
    },

    /**
     * fetchPeriodData
     * Query data for disponibilities and reservationsparking
     * for the selected parking and start->end period
     */
    fetchPeriodData() {
      // -- 1 - Fetch reservations
      this.fetchReservations(
        this.selectedParking,
        this.startDate,
        this.endDate
      );
      // -- 2 - Fetch disponibilities
      this.fetchDisponibilities(
        this.selectedParking,
        this.startDate,
        this.endDate
      );
    },

    /**
     *
     * @param {*} parkings
     */
    isParkingGetBarrier(parkings) {
      return parkings.some(
        (parking) => parking.barriers && parking.barriers.length > 0
      );
    },

    flatter(obj) {
      if (!obj) return [];
      let immat = [];
      Object.keys(obj).forEach((key) => {
        immat = [...immat, obj[key]];
      });
      return immat.flat();
    },
  },
};
</script>

<style scoped>
.custom-date-picker {
  height: 36px;
  margin-left: 0.5rem;
  border: 1px solid #dee2e6;
  padding: 0 16px;
  border-radius: 8px;
  color: #8994a8;
}

label {
  margin-left: 2rem;
}

.period-selector {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
</style>
