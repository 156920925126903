var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-12 w-full"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Liste")]),(_vm.reservations.length === 0)?_c('div',[_c('p',[_vm._v("Pas de réservation sur la période")])]):_vm._e(),_c('v-card',[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',[_c('v-text-field',{staticClass:"\n            my-6\n            input-style\n            font-size-input font-weight-normal\n            placeholder-lighter\n            input-alternative input-icon\n            border\n          ",attrs:{"rounded-sm":"","hide-details":"","outlined":"","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","placeholder":"Nom, email, periode, place, plaque d'immatriculation, date"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"rgba(0,0,0,.6)","size":".875rem"}},[_vm._v("fas fa-search")])],1)],2)],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":"","min-width":"300","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-bolder btn-outline-default py-4 px-2",attrs:{"elevation":"0","color":"#fff","small":""}},'v-btn',attrs,false),on),[_vm._v(" Autres actions ")])]}}])},[_c('v-list',{staticClass:"py-0d"},[_c('v-list-item',{staticClass:"pa-4 list-item-hover-active",on:{"click":_vm.cancelMultipleReservation}},[_c('v-list-item-title',{staticClass:"p-6"},[_vm._v("Annuler "+_vm._s(_vm.selectedItems.length)+" réservation"+_vm._s(_vm.selectedItems.length > 1 ? "s" : "")+" ")])],1)],1)],1),_c('v-btn',{staticClass:"\n            font-weight-bolder\n            btn-danger\n            bg-gradient-info\n            py-4\n            px-4\n            mt-auto\n            ml-2\n          ",attrs:{"elevation":0,"color":"#cb0c9f","small":""},on:{"click":_vm.convertToXLS}},[_vm._v(" Exporter la table ")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredData,"single-select":false,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"fixed-header":"","maxheight":"70vh","item-key":"resaUserId","show-select":"","items-per-page":_vm.filteredData.length,"hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.period.toUpperCase())+" ")]}},{key:"item.userSurname",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('div',[_c('p',{staticClass:"text-sm font-weight-bold mt-4 mb-0"},[_vm._v(" "+_vm._s(item.userSurname)+" "+_vm._s(item.userName)+" ")]),_c('p',{staticClass:"text-sm text-secondary"},[_vm._v(" "+_vm._s(item.userEmail || "n/c")+" ")])]):_c('div',[_vm._v("n/c")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-4 p-0 text-blue",staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.cancelReservation(item.resaid, item.userId)}}},[_vm._v(" Annuler la réservation ")])]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }