<template>
  <div>
    <v-dialog v-model="dialog" width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="0"
          color="#fff"
          class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
          small
          v-bind="attrs"
          v-on="on"
        >
          DÉCLENCHER UNE OUVERTURE
        </v-btn>
      </template>

      <v-card class="p-6">
        <v-card-title class="text-h5"> Déclencher une ouverture </v-card-title>

        <v-card-text>
          <TriggOpeningForm @cancelForm="closeDialog"></TriggOpeningForm>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TriggOpeningForm from "./TriggOpeningForm.vue";

export default {
  name: "TriggOpening",
  components: {
    TriggOpeningForm,
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    triggBtn() {
      console.log("CLIKED GRADIENT");
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
