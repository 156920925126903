<template>
  <v-card
    class="
      card-shadow
      border-radius-xl
      px-4
      py-4
      w-100
      d-flex
      justify-space-between
      align-center
    "
  >
    <h4>Gestion Courante</h4>
    <div class="d-flex">
      <TriggOpening v-if="getBarriers"></TriggOpening>
      <CreateReservation
        @newReservationDone="newReservationDone"
      ></CreateReservation>
    </div>
  </v-card>
</template>

<script>
import CreateReservation from "../../components/Dynapark/CreateReservation.vue";
import TriggOpening from "../../components/Dynapark/TriggOpening.vue";

export default {
  name: "GestionCourante",
  components: {
    TriggOpening,
    CreateReservation,
  },

  computed: {
    getBarriers() {
      return this.$store.getters.isParkingGetBarrier;
    },
  },

  methods: {
    newReservationDone() {
      this.$emit("newReservationDone");
    },
  },
};
</script>

<style scoped></style>
