/**
 *
 * @param {*} objRes
 * @returns {String}
 */

export function reservation400(objRes) {
  if (objRes.limitType === "delayMaxDay") {
    return {
      fr: `Vous ne pouvez pas réserver au delà de ${objRes.delayMaxDay} jours`,
    };
  }

  if (objRes.limitType === "delayMinDay") {
    return {
      fr: `Vous ne pouvez pas réserver avant ${objRes.delayMinDay} jours`,
    };
  }

  if (objRes.limitType === "reservationExists") {
    return {
      fr: `Vous avez déjà une réservation durant cette période`,
    };
  }

  if (objRes.limitType === "noFreeSpots") {
    return {
      fr: `Il n'y a pas de place disponible`,
    };
  }

  if (objRes.limitType === "reservationCount") {
    return {
      fr: `Vous avez atteint votre quota. ${objRes.count}/${objRes.maxUsage} sur une période de ${objRes.maxUsagePeriod} jours`,
    };
  }
}
